<template lang="html">
  <div class="leftNav" :style="{ width: isCollapse ? '64px' : '173px' }">

    <div class="navname">市场管理</div>
    <div class="navmenu">
      <!-- :default-openeds="defaultOpeneds" -->
      <el-menu :default-active="act" class="el-menu-vertical-demo" router @open="jumpUrl" unique-opened ref="menuTree"
        :collapse="isCollapse">
        <div v-for="(item, index) in navList" :key="index">
          <el-submenu :index="item.url" v-if="item.child && haspermission(item)">
            <template slot="title">
              <i :class="item.icon || 'el-icon-location'" v-show="isCollapse" style="font-size: 24px"></i>
              <span slot="title" v-show="!isCollapse">{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <template v-for="(items, indexs) in item.child">
                <el-menu-item v-if="haspermission(items) && haspermission(items)" :index="items.url" :key="indexs">
                  {{ items.name }}
                </el-menu-item>
              </template>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item v-if="!item.child && haspermission(item)" :index="item.url">
            <i :class="item.icon || 'el-icon-location'" v-show="isCollapse" style="font-size: 24px"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script>
import list from "../plugin/leftNav";
export default {
  name: "leftNav",
  props: ['isCollapse'],
  data () {
    return {
      act: "/",
      accessId: "",
      is_admin: 0,
      navList: [],
      jump: "",
      defaultOpeneds: [],
    };
  },
  created () {
    this.navList = list;
  },
  watch: {
    $route: {
      handler (to) {
        this.setCurrentRoute();
      },
      immediate: true,
    },
  },
  methods: {
    setCurrentRoute () {
      let url = this.$route.path;
      if (url == '/merchants' || url == '/merchants_temporary') {
        this.act = '/merchants';
      } else if (url == '/contract/contractlist' || url == '/contract/editcontract' || url == '/contract/contractdetail') {
        this.act = '/contract/contractlist';
      } else if (url == '/contract/contractSetting/contractsSetting' || url == '/contract/contractSetting/contractfnsetting') {
        this.act = '/contract/contractSetting/contractsSetting';
      } else if (url == '/charge/merchantbill/basebill' || url == '/charge/merchantbill/daybill' || url == '/charge/merchantbill/chargeoff' || url == '/charge/merchantbill/basebilldetail') {
        this.act = '/charge/merchantbill/basebill';
      } else if (url == '/charge/chargeoffline' || url == '/charge/addCharge') {
        this.act = '/charge/chargeoffline';
      } else if (url == '/charge/chargesetting/chargeset' || url == '/charge/chargesetting/daychargeset' || url == '/charge/chargesetting/contractchargeset' || url == '/charge/chargesetting/assistantContractChargeset') {
        this.act = '/charge/chargesetting/chargeset';
      } else if (url == '/complain/complainlist/pending' || url == '/complain/complainlist/processed') {
        this.act = '/complain/complainlist/pending';
      } else if (url == '/finance/accountcheck/lineBill' || url == '/finance/accountcheck/cashBill' || url == '/finance/accountcheck/historyRecord') {
        this.act = '/finance/accountcheck/lineBill';
      } else if (url == '/finance/financialStatement/merchantList' || url == '/finance/financialStatement/shopList') {
        this.act = '/finance/financialStatement/merchantList';
      } else if (url == '/finance/refund' || url == '/finance/refundhis') {
        this.act = '/finance/refund';
      } else if (url == '/opermanSetup/manage' || url == '/opermanSetup/roleManage') {
        this.act = '/opermanSetup/manage';
      } else if (url == '/contract/batchcontract' || url == '/contract/batchcontractDetail') {
        this.act = '/contract/batchcontract';
      } else if (url == '/meter/meterManage' || url == '/meter/rechargeRecord') {
        this.act = '/meter/meterManage';
      } else if (url == '/meterWater/meterManage' || url == '/meterWater/rechargeRecord') {
        this.act = '/meterWater/meterManage';
      } else {
        this.act = url;
      }
    },
    haspermission (item) {
      if (item.ispermission) return true;
      return false
    },
    jumpUrl (item) {
      let arr = list.find(items => items.url == item)
      if (arr) {
        let data = arr.child;
        let ite = data.find(items => items.ispermission == true)
        if (ite) {
          this.$router.push(ite.url)
        }
      }
    }

  },
};
</script>

<style lang="scss" scoped>
.leftNav {
  border-right: 2px solid #f4f4f4;
  overflow-x: hidden;
  transition: all 0.5s;
  position: relative;
  width: 173px;

  .navname {
    width: 100%;
    height: 46px;
    line-height: 46px;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    border-bottom: 1px solid #dedede;
  }

  .navmenu {
    height: calc(100vh - 98px);
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 2px;
      display: none;
    }

    &::-webkit-scrollbar-track {
      background: rgb(239, 239, 239);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #333;
    }

    &::-webkit-scrollbar-corner {
      background: #179a16;
    }
  }

  .el-menu {
    border: none;
  }

  /deep/ .el-menu-item.is-active {
    background: #f4f6f7 !important;
    color: #3d7eff !important;
  }

  /deep/ .el-menu-item:hover {
    background: #f4f6f7 !important;
    color: #3d7eff !important;
  }

  /deep/ .el-menu-item {
    height: 46px;
    line-height: 46px;
  }

  /deep/ .el-submenu__title {
    height: 46px;
    line-height: 46px;
  }

  /deep/ .el-submenu .el-submenu__title:hover {
    background: #f4f6f7 !important;
    color: #3d7eff !important;
  }
}
</style>
