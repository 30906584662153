<template>
  <div class="index">
    <el-container>
      <el-header width="100%" height="50px">
        <MuyuNavigationModel class="muyu" :userInfo="userInfo && userInfo.worker" @outLogin="outLogin">
        </MuyuNavigationModel>
      </el-header>
      <el-container>
        <el-aside width="auto">
          <img src="../assets/img/lr.png" :class="['el-icon-s-unfold']" @click="isCollapse = !isCollapse"
            :style="{ left: isCollapse ? '64px' : '173px', zIndex: isCollapse ? 9 : 1 }" />
          <img src="../assets/img/rl.png" :class="['el-icon-s-unfold']" @click="isCollapse = !isCollapse"
            :style="{ left: isCollapse ? '64px' : '173px', zIndex: !isCollapse ? 9 : 1 }" />
          <!--        <i :class="['el-icon-s-unfold']" :style="{left:isCollapse?'48px':'155px',transform:isCollapse?'rotate(0deg)':'rotate(180deg)'}"  @click="isCollapse=!isCollapse" ></i>-->
          <leftNav :isCollapse="isCollapse"></leftNav>
        </el-aside>
        <el-main>
          <keep-alive include="/contract/contractSetting/contractsSetting">
            <router-view></router-view>
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import leftNav from "../components/LeftNav.vue";
import store from "@/store";
export default {
  name: "index",
  components: {
    leftNav,
  },
  data () {
    return {
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
      isCollapse: false,
    };
  },
  created () {
    //do something after creating vue instance
  },
  methods: {
    outLogin () {
      window.localStorage.clear();
      // store.commit("CLEARROUTER")
      this.$router.replace("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.el-header {
  padding: 0;
}

.el-aside {
  overflow-x: hidden;
}

.el-main {
  padding: 0 20px;
  height: calc(100vh - 50px);
  flex: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.el-icon-s-unfold {
  font-size: 30px;
  position: fixed;
  /* right: 0; */
  top: 50%;
  left: 64px;
  z-index: 68;
  transition: all 0.5s;
  cursor: pointer;
  width: 30px;
  height: 42px;
}

/deep/ .muyu {
  .nav {
    .user {
      margin-right: 30px;
    }
  }
}
</style>
